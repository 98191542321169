import { memo, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Select,
  useSelect,
  Sky,
  ContactShadows,
  Edges,
  Environment,
  OrbitControls,
  MeshTransmissionMaterial,
  useCursor,
  Grid,
  AccumulativeShadows,
  RandomizedLight,
} from "@react-three/drei";

import { useControls, button, buttonGroup, folder } from "leva";
import { Checkbox, Slider } from "@mui/material";
import axios from "axios";

function calculatePlantBoxCost(h, b, l, i) {
  const Grundpreis = 50.0;
  const Ablaufrohr = 20.0;
  const MaterialAluminium = 4.5;
  const Vorbereitungsarbeit = 15.0;
  const ScherenUndAbkanten = 44.0;
  const SchweissnahtInklBearbeitung = 37.5;
  const LackierenAlu = 25.0;
  const MaterialAluminiumAuflager = 4.5;
  const SchweissnahtInklBearbeitungAuflager = 37.5;
  const VerstrebungInklArbeit = 15.0;

  const Isolation = 25;

  const materialAluminiumKg =
    (2 * (h * b + h * l) + b * l) * 3 * 0.0000027 +
    2 * (l + b) * 60 * 3 * 0.0000027;
  const materialAluminium = materialAluminiumKg * MaterialAluminium;

  const vorbereitungsarbeitKg =
    (2 * (h * b + h * l) + b * l + 2 * (l + b) * 60) / 1000000;
  const vorbereitungsarbeit = vorbereitungsarbeitKg * Vorbereitungsarbeit;

  const scherenUndAbkantenKg =
    (2 * (h * b + h * l) + b * l + 2 * (l + b) * 60) / 1000000;
  const scherenUndAbkanten = scherenUndAbkantenKg * ScherenUndAbkanten;

  const schweissnahtInklBearbeitungKg = (4 * h + 2 * (l + b)) / 1000;
  const schweissnahtInklBearbeitung =
    schweissnahtInklBearbeitungKg * SchweissnahtInklBearbeitung;

  const lackierenAluKg =
    ((2 * (h * b + h * l) + b * l + 2 * (l + b) * 60) / 1000000) * 2;
  const lackierenAlu = lackierenAluKg * LackierenAlu;

  const materialAluminiumAuflagerKg = (2 * l * 0.875) / 1000;
  const materialAluminiumAuflager =
    materialAluminiumAuflagerKg * MaterialAluminiumAuflager;

  const schweissnahtInklBearbeitungAuflagerKg = (l / 1000) * 0.5;
  const schweissnahtInklBearbeitungAuflager =
    schweissnahtInklBearbeitungAuflagerKg * SchweissnahtInklBearbeitungAuflager;

  const verstrebungInklArbeitKg = Math.floor(l / 800);
  const verstrebungInklArbeit = verstrebungInklArbeitKg * VerstrebungInklArbeit;

  var sum =
    Grundpreis +
    Ablaufrohr +
    materialAluminium +
    vorbereitungsarbeit +
    scherenUndAbkanten +
    schweissnahtInklBearbeitung +
    lackierenAlu +
    materialAluminiumAuflager +
    schweissnahtInklBearbeitungAuflager +
    verstrebungInklArbeit;

  const verpackung = 0.02 * sum;

  const isolationKg = (2 * (h * l + h * b)) / 1000000;
  const isolation = isolationKg * Isolation;

  sum = sum + verpackung + (i ? isolation : 0);

  const verhandlungsmarge = 0.2 * sum;

  sum = sum + verhandlungsmarge;

  const volume = (l * b * h) / 1000000000;

  const empty = materialAluminiumAuflagerKg + materialAluminiumKg;

  const filled = empty + (h * l * b * 2500) / 1000000000;

  // console.log(
  //   "materialAluminiumKg",
  //   materialAluminiumKg,
  //   "materialAluminium",
  //   materialAluminium,
  //   "vorbereitungsarbeitKg",
  //   vorbereitungsarbeitKg,
  //   "vorbereitungsarbeit",
  //   vorbereitungsarbeit,
  //   "scherenUndAbkantenKg",
  //   scherenUndAbkantenKg,
  //   "scherenUndAbkanten",
  //   scherenUndAbkanten,
  //   "schweissnahtInklBearbeitungKg",
  //   schweissnahtInklBearbeitungKg,
  //   "schweissnahtInklBearbeitung",
  //   schweissnahtInklBearbeitung,
  //   "lackierenAluKg",
  //   lackierenAluKg,
  //   "lackierenAlu",
  //   lackierenAlu,
  //   "materialAluminiumAuflagerKg",
  //   materialAluminiumAuflagerKg,
  //   "materialAluminiumAuflager",
  //   materialAluminiumAuflager,
  //   "schweissnahtInklBearbeitungAuflagerKg",
  //   schweissnahtInklBearbeitungAuflagerKg,
  //   "schweissnahtInklBearbeitungAuflager",
  //   schweissnahtInklBearbeitungAuflager
  // );

  return {
    price: sum.toFixed(0),
    volume: volume.toFixed(2),
    empty: empty.toFixed(2),
    filled: filled.toFixed(2),
  };
}

function Cube({ color = "#6d6a85", ...props }) {
  const materialProps = {
    color: color,
    roughness: 0.5,
    thickness: 10,
    envMapIntensity: 1.5,
    // transmission: 1,
    metalness: 0.5,
  };
  return (
    <mesh castShadow receiveShadow {...props}>
      <boxGeometry />
      <meshStandardMaterial {...materialProps} />
    </mesh>
  );
}

const marks = [
  {
    value: 1,
    label: "Default",
  },
];

export default function App() {
  const depth = 0.05;

  const refX = useRef();
  const refY = useRef();
  const refZ = useRef();

  const [x, setX] = useState(500);
  const [y, setY] = useState(1000);
  const [z, setZ] = useState(500);
  const [color, setColor] = useState("#4A4E57");

  const [isolation, setIsolation] = useState(true);


  useEffect(() => {
    refX.current.value = x;
  }, [x]);

  useEffect(() => {
    refY.current.value = y;
  }, [y]);

  useEffect(() => {
    refZ.current.value = z;
  }, [z]);

  const [data, setData] = useState({
    price: 0,
    volume: 0,
    empty: 0,
    filled: 0,
  });

  useEffect(() => {
    setData(calculatePlantBoxCost(z, x, y, isolation));
  }, [x, y, z, isolation]);

  const offset = depth / 2 - 0.0001;

  const [open, setOpen] = useState(true);

  const [loader, setLoader] = useState(false);


  const createProductAndAddToCart = async () => {
    try {
      setLoader(true);
      const createResponse = await axios.post(
        'https://plantbox.ch/wp-json/wc/v3/products',
        {
          name: `
          <strong>Plantbox</strong>
           <br /> Länge: ${refY.current.value}
           <br /> Breite: ${refX.current.value}
           <br /> Höhe: ${refZ.current.value}
           <br /> Color: ${color === "#4A4E57" ? "Eisenglimmer schwarz-silber" : color === "#293133" ? "Anthrazit matt RAL 7016" : color === "#ffffff" ? "Weiss matt RAL 9010" : color === "#8f8f8f" ? "Graualuminium matt RAL 9007" : "Eisenglimmer schwarz-silber"}
          ${isolation ? "<br />mit Isolation": "<br />ohne Isolation" }
          `,
          regular_price: data.price,
          images: [
            {
              src: "http://plantbox.ch/wp-content/uploads/2023/11/BILD_05_plant_box_FINAL-scaled.jpg"
            },
           
          ],
          attributes: [
            {
              name: 'Länge',
              visible: true,
              variation: false,
              options: [refY.current.value], // dergo Lange
            },
            {
              name: 'Breite',
              visible: true,
              variation: false,
              options: [refX.current.value],  // dergo Breite
            },
            {
              name: 'Höhe',
              visible: true,
              variation: false,
              options: [refZ.current.value], // dergo Hohe
            },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          auth: {
            username: 'ck_cdc4bbd6eefa2cd4f07414b41b509d0cd4acb223',
            password: 'cs_5f2b7249c689886b2cdbdc54db6355da3f48c008',
          },
        }
      );
  
      if (createResponse.status === 201) {
        console.log('Product created successfully:', createResponse.data);
  
        
        const productId = createResponse.data.id;
  
        
        // const addToCartResponse = await axios.put(
        //   `https://plantbox.ch/cart?add-to-cart=${productId}`,
        //   {
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     auth: {
        //       username: 'ck_cdc4bbd6eefa2cd4f07414b41b509d0cd4acb223',
        //       password: 'cs_5f2b7249c689886b2cdbdc54db6355da3f48c008',
        //     },
        //   }
        // );
  
        if (createResponse.status === 201) {
          console.log('Product added to cart successfully');
          window.location.href = `https://plantbox.ch/cart?add-to-cart=${productId}` 
          setLoader(false);
        } else {
          console.error('Failed to add product to cart:', createResponse.data);
        }
      } else {
        console.error('Failed to create product:', createResponse.data);
      }
    } catch (error) {
      console.error('Error creating product or adding to cart:', error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-full p-3">
      <div className="relative h-full min-h-[500px]">
        <Canvas
          style={{ height: "100%" }}
          dpr={[1, 2]}
          orthographic
          className="rounded-2xl"
          // shadows={"soft"}
          camera={{ position: [-10, 10, 10], zoom: 160 }}
        >
          <color attach="background" args={["#e6e6e6"]} />

          <pointLight position={[5, 5, 5]} />

          <Cube
            color={color}
            scale={[x / 500, z / 500, depth]}
            position={[0, 0, -y / 500 / 2 + offset]}
          />
          <Cube
            color={color}
            scale={[x / 500, z / 500, depth]}
            position={[0, 0, y / 500 / 2 - offset]}
          />
          <Cube
            color={color}
            scale={[depth, z / 500, y / 500]}
            position={[-x / 500 / 2 + offset, 0, 0]}
          />
          <Cube
            color={color}
            scale={[depth, z / 500, y / 500]}
            position={[x / 500 / 2 - offset, 0, 0]}
          />
          <Cube
            color={color}
            scale={[x / 500, depth, y / 500]}
            position={[0, -z / 500 / 2 + offset, 0]}
          />
          <Cube
            color={color}
            scale={[depth, depth, y / 500 - depth]}
            position={[x / 500 / 2 - depth, -z / 500 / 2 - offset, 0]}
          />
          <Cube
            color={color}
            scale={[depth, depth, y / 500 - depth]}
            position={[-x / 500 / 2 + depth, -z / 500 / 2 - offset, 0]}
          />

          {isolation ? (
            <>
              <Cube
                color={"#595f9c"}
                scale={[x / 500, z / 500 - 0.08, offset]}
                position={[0, -0.04, -y / 500 / 2 + offset + 0.02]}
              />
              <Cube
                color={"#595f9c"}
                scale={[x / 500, z / 500 - 0.08, offset]}
                position={[0, -0.04, y / 500 / 2 - offset - 0.02]}
              />
              <Cube
                color={"#595f9c"}
                scale={[offset, z / 500 - 0.08, y / 500]}
                position={[-x / 500 / 2 + offset + 0.02, -0.04, 0]}
              />
              <Cube
                color={"#595f9c"}
                scale={[offset, z / 500 - 0.08, y / 500]}
                position={[x / 500 / 2 - offset - 0.02, -0.04, 0]}
              />
              <Cube
                color={"#595f9c"}
                scale={[x / 500, offset, y / 500]}
                position={[0, -z / 500 / 2 + offset + 0.02, 0]}
              />
            </>
          ) : (
            <></>
          )}

          <Environment files="potsdamer_platz_1k.hdr" />

          {/* <ContactShadows
          frames={20}
          position={[0, -z / 500 / 2, 0]}
          scale={10}
          opacity={1}
          // far={1}
          blur={4}
        /> */}
          <OrbitControls
            makeDefault
            rotateSpeed={2}
            minPolarAngle={0}
            minZoom={80}
            maxZoom={200}
            // maxPolarAngle={Math.PI / 2.5}
          />
          {/* <Sky /> */}
          <Ground z={-z / 500 / 2 - depth} />
          <RandomizedLight amount={8} radius={4} position={[5, 5, -10]} />
        </Canvas>
        <div
          onClick={() => setOpen(!open)}
          className={` flex md:hidden absolute cursor-pointer right-0 bottom-0 md:!top-0 m-4 rounded-lg aspect-square w-12 h-12 justify-center items-center transition-all ${
            !open ? "text-blue-800 bg-white" : "!text-white !bg-blue-600"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-adjustments"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
            <path d="M6 4v4"></path>
            <path d="M6 12v8"></path>
            <path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
            <path d="M12 4v10"></path>
            <path d="M12 18v2"></path>
            <path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
            <path d="M18 4v1"></path>
            <path d="M18 9v11"></path>
          </svg>
        </div>
      </div>

      {open ? (
        <div className="bottom-0 right-0 p-4 pt-8 px-4 md:px-10 md:pt-10 md:p-10 md:w-[450px] w-full flex-col">
          <div className="flex-col bg-white mb-6 md:mb-12">
            <div className="flex items-center ">
              <p className="w-28 text-sm">Länge</p>
              <Slider
                size="small"
                min={300}
                max={1500}
                value={y}
                step={1}
                onChange={(event, value) => setY(value)}
                // style={{ width: 200 }}
                defaultValue={500}
                aria-label="Small"
                valueLabelDisplay="auto"
              />

              <input
                type="number"
                min={300}
                max={1500}
                ref={refY}
                onChange={(event, value) => {
                  
                  setY(parseInt(event.target.value));
                }}
                step="1"
                className="w-16 rounded-md border-slate-300 py-0 md:py-1 focus:border-slate-600 outline-0 border ml-4 px-2 text-center"
              ></input>
              <span className="ml-1">mm</span>
            </div>

            <div className="flex items-center mt-0 md:mt-2">
              <p className="w-28 text-sm">Breite</p>
              <Slider
                size="small"
                min={300}
                max={2000}
                value={x}
                step={1}
                onChange={(event, value) => setX(value)}
                // style={{ width: 200 }}
                defaultValue={500}
                aria-label="Small"
                valueLabelDisplay="auto"
              />
              <input
                type="number"
                min={300}
                max={2000}
                ref={refX}
                onChange={(event, value) => {
                  setX(parseInt(event.target.value));
                }}
                step="1"
                className="w-16 rounded-md border-slate-300 py-0 md:py-1 focus:border-slate-600 outline-0 border ml-4 px-2 text-center"
              ></input>
              <span className="ml-1">mm</span>
            </div>

            <div className="flex items-center mt-0 md:mt-2">
              <p className="w-28 text-sm">Höhe</p>
              <Slider
                size="small"
                min={300}
                max={1000}
                step={3}
                value={z}
                onChange={(event, value) => setZ(value)}
                // style={{ width: 200 }}
                defaultValue={500}
                aria-label="Small"
                valueLabelDisplay="auto"
              />

              <input
                type="number"
                min={300}
                max={1000}
                ref={refZ}
                onChange={(event, value) => {
                  setZ(parseInt(event.target.value));
                }}
                step="1"
                className="w-16 rounded-md border-slate-300 py-0 md:py-1 focus:border-slate-600 outline-0 border ml-4 px-2 text-center"
              ></input>
              <span className="ml-1">mm</span>
            </div>

            <div
              onClick={() => setIsolation(!isolation)}
              className="flex justify-between px-3 rounded-md mt-2 md:mt-4 items-center w-full cursor-pointer  border border-slate-200 gap-2 mb-1 md:mb-3"
            >
              <p className="text-sm">Isolation</p>
              <Checkbox
                size="small"
                checked={isolation}
                onChange={(event, value) => setIsolation(value)}
                // style={{ width: 200 }}
                aria-label="Small"
                valueLabelDisplay="auto"
              />
            </div>

            <div className="flex items-center mt-4">
              <p className="w-20 text-sm">Farben</p>
              <div className="flex flex-1 justify-end gap-2">
                <Color c="#4A4E57" color={color} setColor={setColor}></Color>
                <Color c="#293133" color={color} setColor={setColor}></Color>
                <Color c="#ffffff" color={color} setColor={setColor}></Color>
                <Color c="#8f8f8f" color={color} setColor={setColor}></Color>

                {/* <button className="w-10 h-10 rounded-full ml-2 bg-gray-200"></button>
            <button className="w-10 h-10 rounded-full ml-2 bg-gray-200"></button>

            <button className="w-10 h-10 rounded-full ml-2 bg-gray-200"></button> */}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
            <div className="flex flex-1 text-sm items-center p-2 px-4 border border-slate-200 rounded-md justify-between">
              <p className="">Preis:</p>
              <b className="">{data.price}CHF</b>
            </div>

            <div className="flex flex-1 text-sm items-center p-2 px-4 border border-slate-200 rounded-md justify-between">
              <p className="">Volumen:</p>
              <b className="">
                {data.volume}m<sup>3</sup>
              </b>
            </div>

            <div className="flex flex-1 text-sm items-center p-2 px-4 border border-slate-200 rounded-md justify-between">
              <p className=" ">Gewicht leer:</p>
              <b className="">{data.empty}kg</b>
            </div>

            <div className="flex flex-1 text-sm items-center p-2 px-4 border border-slate-200 rounded-md justify-between">
              <p className="">Gewicht gefüllt:</p>
              <b className="">{data.filled}kg</b>
            </div>
          </div>
          <button onClick={createProductAndAddToCart} className="flex items-center justify-center bg-blue-600 text-white p-2 py-4 mt-4 mb-8 md:mb-0 rounded-lg w-full">
            <p className="font-semibold">in den Warenkorb legen</p>
          </button>
          <p className="p-2 px-4">
            *Der Konfigurator dient nur zur Visualisierung und entspricht nicht zu 100% der genauen Ausführung. Die genauen Details und Farbtöne finden sie auf den Bilder der Webseite. Bei Fragen können sie sich gerne per Kontaktformular bei uns melden.
          </p>
        </div>
      ) : (
        <></>
      )}
      {
        loader && 
          <div className="loader" style={{backgroundColor: "#fff", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", opacity: "0.3", display: "flex", justifyContent: "center", alignItems: "center"}}>
           <svg version="1.1" width="100px" height="100px" class="svg-loader" x="0px" y="0px" viewBox="0 0 80 80"> <path id="spinner" fill="#6d6a85" d="M40,72C22.4,72,8,57.6,8,40C8,22.4, 22.4,8,40,8c17.6,0,32,14.4,32,32c0,1.1-0.9,2-2,2 s-2-0.9-2-2c0-15.4-12.6-28-28-28S12,24.6,12,40s12.6, 28,28,28c1.1,0,2,0.9,2,2S41.1,72,40,72z"> <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 40 40" to="360 40 40" dur="0.75s" repeatCount="indefinite" /> </path></svg>
          </div>
      }
          <a href="https://plantbox.ch/">
        <button style={{
          position: "absolute",
          top: "18px",
          left: "18px",
          background: "#fff",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px"

        }}>
            <svg width="22" height="22" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8332 7.00033H1.1665M1.1665 7.00033L6.99984 12.8337M1.1665 7.00033L6.99984 1.16699" stroke="#667085" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
          </a>
    </div>
  );
}

function Color({ c, color, setColor }) {  
  const active = c === color;
  return (
    <button
      onClick={() => setColor(c)}
      style={{ backgroundColor: c }}
      className={
        "md:w-10 md:h-10 h-8 w-8 rounded-full border border-slate-200 border-1" +
        (active ? " !border-2 shadow-lg !border-blue-500" : "")
      }
      title={c === "#4A4E57" ? "Eisenglimmer schwarz-silber" : c === "#293133" ? "Anthrazit matt RAL 7016" : c === "#ffffff" ? "Weiss matt RAL 9010" : c === "#8f8f8f" ? "Graualuminium matt RAL 9007" : "Eisenglimmer schwarz-silber"}
      // 
    ></button>
  );
}

function Ground({ z }) {
  const gridConfig = {
    cellSize: 0.5,
    cellThickness: 0.5,
    cellColor: "#6d6a85",
    sectionSize: 3,
    sectionThickness: 1,
    sectionColor: "#6d6a85",
    fadeDistance: 20,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
  };
  return <Grid position={[0, z, 0]} {...gridConfig} />;
}
